import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios';
import '../styles/globals.css'
import { useGraphQLClient } from '../lib/graphql-client'
import { ClientContext } from 'graphql-hooks'
import { Provider } from '../store'
import Layout from '../components/Layout';
import GoogleAnalyticsTags from '../components/common/GoogleAnalyticsTags'
import { log } from '@logtail/next';

export default function App({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        log.info('Page Load', {
          metadata: {
            url: window.location.href,
            userAgent: navigator.userAgent,
            type: 'initial_load'
          }
        });
      }
    }, 0);

    const handleRouteChange = async (url, { shallow }) => {
      const url_parts = window.location.hostname.split('.');
      if (url_parts.length > 2) {
        access_log(url_parts[0])
      }
      if (typeof window !== 'undefined') {
        const userAgent = navigator.userAgent;
        await log.info('Route Change', {
          metadata: {
            url: window.location.href,
            userAgent: userAgent,
            type: 'route_change'
          }
        });
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  const access_log = (subdomain) => {
    const axiosInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      headers: {
        "content-type": "application/json",
      },
    });
    (async () => {
      return await axiosInstance
        .post("access_logs", {
          subdomain: subdomain,
        })
    })();
  };

  const graphQLClient = useGraphQLClient(pageProps.initialGraphQLState)

  return (
    <ClientContext.Provider value={graphQLClient}>
      <Provider>
        <Layout>
          <Component {...pageProps} />
          <GoogleAnalyticsTags />
        </Layout>
      </Provider>
    </ClientContext.Provider>
  );
}

