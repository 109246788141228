import Home from '../components/Home';
import axios from 'axios';
import { useRouter } from 'next/router';
import { withLogtailGetServerSideProps } from '@logtail/next';
export const HomePage = props => {
  const router = useRouter();
  return <Home key={router.asPath} data={props.data} data-sentry-element="Home" data-sentry-component="HomePage" data-sentry-source-file="home.tsx" />;
};
export default HomePage;
export const getServerSideProps = withLogtailGetServerSideProps(async ({
  res,
  req,
  log,
  params
}) => {
  try {
    const postsRes = axios.get(process.env.NEXT_PUBLIC_FASTLY + '/api/v1/posts');
    const newslettersRes = axios.get(process.env.NEXT_PUBLIC_FASTLY + '/api/v1/newsletters');
    const response = await Promise.all([postsRes, newslettersRes]);
    log.info('Data fetched successfully', {
      metadata: {
        status: 200,
        path: req.url,
        host: req.headers.host,
        userAgent: req.headers['user-agent'],
        cacheHit: req.headers['x-cache']
      }
    });
    res.setHeader('Cache-Control', 'public, s-maxage=3710, stale-while-revalidate=31536000');
    return {
      props: {
        data: {
          posts: response[0].data,
          newsletters: response[1].data
        }
      }
    };
  } catch (error) {
    log.error('Error fetching data', {
      metadata: {
        error: error.message,
        status: 500,
        path: req.url,
        host: req.headers.host,
        userAgent: req.headers['user-agent'],
        cacheHit: req.headers['x-cache']
      }
    });
    return {
      notFound: true
    };
  }
});