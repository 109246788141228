import axios from 'axios';

export const fetchGaId = async (subdomain) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_FASTLY}/api/v1/newsletters/${subdomain}/ga_trackings`
    );
    return response.data?.ga_tracking;
  } catch (error) {
    return null;
  }
};