import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link';
import { Store } from '../../store';
export const StartButton = ({
  free
}) => {
  const {
    state,
    dispatch
  } = useContext(Store);
  const text = free ? '無料ではじめる' : 'はじめる';
  if (!state.currentUser) {
    return <Link href="/signup" className="w-fit mx-auto px-4 py-4 rounded-xl bg-gray-100 hover:bg-gray-300">
        {text}
      </Link>;
  }
  if (state.currentUser.__typename === 'Subscriber') {
    return <>
        <Link href="/workspace" className="w-fit mx-auto px-4 py-4 rounded-xl bg-gray-100 hover:bg-gray-300">
          {text}
        </Link>
        <div className="mt-6 text-xs w-fit mx-auto">
          ・パスワード登録が必要です
        </div>
      </>;
  }
  return <Link href="/workspace" className="w-fit mx-auto px-4 py-4 rounded-xl bg-gray-100 hover:bg-gray-300" data-sentry-element="Link" data-sentry-component="StartButton" data-sentry-source-file="StartButton.tsx">
      {text}
    </Link>;
};
export default StartButton;