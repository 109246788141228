import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link';
import { Store } from '../../../store';
import { useQuery, useMutation, useManualQuery } from 'graphql-hooks';
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
export const CONFIRM_NOTIFICATIONS_MUTATION = `
  mutation confirmNotifications {
    confirmNotifications(input: {}) {
      result
    }
  }
`;
export const Notifications = ({}) => {
  const {
    state,
    dispatch
  } = useContext(Store);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [notifications, setNotifications] = useState<JSX.Element[]>([]);
  const [unread, setUnread] = useState(false);
  const [confirmNotifications, {
    loading,
    error,
    data
  }] = useMutation(CONFIRM_NOTIFICATIONS_MUTATION);
  const userImage = subscriber => {
    if (subscriber?.user?.imageUrl) {
      return <img src={subscriber.user.imageUrl} className='rounded-full w-8 h-8 max-w-fit object-cover bg-white hover:bg-gray-200 cursor-pointer' alt='user' />;
    } else {
      return <div className="rounded-full w-8 h-8 my-auto bg-gray-600 text-white text-xl hover:bg-gray-400 cursor-pointer flex justify-center items-center">
          {subscriber.email?.charAt(0)?.toUpperCase()}
        </div>;
    }
  };
  const truncate = text => {
    return ("" + text).length > 16 ? text.substring(0, 16) + "..." : text;
  };
  const timeString = str => {
    let d = new Date(str);
    return d.toLocaleString('ja-JP').slice(0, -3);
  };
  const likableMessage = notifiable => {
    if (!notifiable.likable) {
      throw new Error(`likableMessage error: ${JSON.stringify(state?.currentUser?.notifications, null, 2)}`);
      return;
    }
    switch (notifiable.likable.__typename) {
      case 'Post':
        return <div>
            {notifiable.subscriber?.user?.displayName || `${notifiable.subscriber.icon}...`} さんがあなたの記事「
            {truncate(notifiable.likable.title)}」をいいねしました
          </div>;
      case 'Comment':
        return <div>
            {notifiable.subscriber?.user?.displayName || `${notifiable.subscriber.icon}...`} さんがあなたのコメントをいいねしました
          </div>;
      case 'CommentReply':
        return <div>
            {notifiable.subscriber?.user?.displayName || `${notifiable.subscriber.icon}...`} さんがあなたのコメントをいいねしました
          </div>;
    }
  };
  useEffect(() => {
    if (!state.currentUser || !state.currentUser?.notifications) return;
    const items: JSX.Element[] = [];
    for (const n of state.currentUser.notifications.nodes) {
      if (!unread && n.status !== 'confirmed') setUnread(true);
      switch (n.notifiable?.__typename) {
        case 'Like':
          if (!n.notifiable?.likable) continue;
          items.push(<MenuItem>
              <div className="flex items-center py-2 relative">
                {n.status !== 'confirmed' && <div className="absolute top-2 left-0 w-2 h-2 bg-gray-800 rounded-full"></div>}
                <div className="relative w-10 p-0 flex-none">
                  {userImage(n.notifiable.subscriber)}
                </div>
                <Link href={n.notifiable?.likable?.url} className="ml-4">
                  {likableMessage(n.notifiable)}
                  <div className="mt-1 text-gray-400">
                    {timeString(n.createdAt)}
                  </div>
                </Link>
              </div>
            </MenuItem>);
          break;
        case 'Subscription':
          items.push(<MenuItem>
              <div className="flex items-center py-2 relative">
                {n.status !== 'confirmed' && <div className="absolute top-2 left-0 w-2 h-2 bg-gray-800 rounded-full"></div>}
                <div className="relative w-10 p-0 flex-none">
                  {userImage(n.notifiable.subscriber)}
                </div>
                <Link className="ml-4" href={`${window.location.protocol}//${n.notifiable.newsletter.subdomain}.${process.env.NEXT_PUBLIC_DOMAIN}`}>
                  <div>
                  {n.notifiable.subscriber.user?.displayName || n.notifiable.subscriber.email} さんがあなたのニュースレター「
                  {truncate(n.notifiable.newsletter.title)}」を購読しました
                  </div>
                  <div className="mt-1 text-gray-400">
                    {timeString(n.createdAt)}
                  </div>
                </Link>
              </div>
            </MenuItem>);
          break;
        case 'Payment':
          items.push(<MenuItem>
              <div className="flex items-center py-2 relative">
                {n.status !== "confirmed" && <div className="absolute top-2 left-0 w-2 h-2 bg-gray-800 rounded-full"></div>}
                <div className="relative w-10 p-0 flex-none">
                  <img src={n.notifiable?.user?.imageUrl} className="rounded-full w-8 h-8 max-w-fit object-cover bg-white hover:bg-gray-200 cursor-pointer" alt="user" />
                </div>
                <Link className="ml-4" href='https://dashboard.stripe.com/' target="_blank" rel="noopener noreferrer">
                  <div>
                    {n.notifiable.user.displayName} さんが
                    あなたのコンテンツを{n.notifiable?.price}円で購入しました
                  </div>
                  <div className="mt-1 text-gray-400">
                    {timeString(n.createdAt)}
                  </div>
                </Link>
              </div>
            </MenuItem>);
          break;
        case 'Comment':
          items.push(<MenuItem>
              <div className="flex items-center py-2 relative">
                {n.status !== 'confirmed' && <div className="absolute top-2 left-0 w-2 h-2 bg-gray-800 rounded-full"></div>}
                <div className="relative w-10 p-0 flex-none">
                  {userImage(n.notifiable.subscriber)}
                </div>
                <Link className="ml-4" href={n.notifiable.url}>
                  <div className="break-words">
                  {n.notifiable.subscriber?.user?.displayName || `${n.notifiable.subscriber?.icon}...`} さんがあなたの記事「
                  {truncate(n.notifiable.post.title)}」にコメントしました
                  </div>
                  <div className="mt-1 text-gray-400">
                    {timeString(n.createdAt)}
                  </div>
                </Link>
              </div>
            </MenuItem>);
          break;
        case 'CommentReply':
          items.push(<MenuItem>
              <div className="flex items-center py-2 relative">
                {n.status !== 'confirmed' && <div className="absolute top-2 left-0 w-2 h-2 bg-gray-800 rounded-full"></div>}
                <div className="relative w-10 p-0 flex-none">
                  {userImage(n.notifiable.subscriber)}
                </div>
                <Link className="ml-4" href={n.notifiable.url}>
                  <div className="break-words">
                  {n.notifiable.subscriber?.user?.displayName || `${n.notifiable.subscriber?.icon}...`} さんがあなたのコメント「
                  {truncate(n.notifiable.comment.summary)}...」に返信しました
                  </div>
                  <div className="mt-1 text-gray-400">
                    {timeString(n.createdAt)}
                  </div>
                </Link>
              </div>
            </MenuItem>);
          break;
        case 'PromotionEvent':
          items.push(<MenuItem>
              <div className="flex items-center py-2 relative">
                {n.status !== "confirmed" && <div className="absolute top-2 left-0 w-2 h-2 bg-gray-800 rounded-full"></div>}
                <div className="relative w-10 p-0 flex-none">
                  <img src={n.notifiable.subscriber.user.imageUrl} className="rounded-full w-8 h-8 max-w-fit object-cover bg-white hover:bg-gray-200 cursor-pointer" alt="user" />
                </div>
                <Link className="ml-4" href="/dashboard/payments">
                  <div className="break-words">
                    {n.notifiable.subscriber.user.displayName}
                    さんが紹介プロモーションで購入しました。 
                    30日無料の購読期間が追加されました 🎉
                  </div>
                  <div className="mt-1 text-gray-400">
                    {timeString(n.createdAt)}
                  </div>
                </Link>
              </div>
            </MenuItem>);
          break;
      }
    }
    if (items.length === 0) {
      items.push(<MenuItem className="my-3">通知はまだありません</MenuItem>);
    } else {
      items.push(<>
        <hr className="my-3" />

        <MenuItem className="text-center my-2">
          <Link href='/dashboard/notifications'>
            すべての通知を見る
          </Link>
        </MenuItem>
        </>);
    }
    setNotifications(items);
  }, [state.currentUser]);
  if (notifications.length === 0) return null;
  return <Menu placement="bottom-end" handler={e => {
    setOpenMenu(!openMenu);
    setUnread(false);
    confirmNotifications();
  }} open={openMenu} data-sentry-element="Menu" data-sentry-component="Notifications" data-sentry-source-file="Notifications.tsx">
      <MenuHandler data-sentry-element="MenuHandler" data-sentry-source-file="Notifications.tsx">
        <div className="relative w-fit min-w-18 pl-2 mr-1 bg-white">
          {unread && <div className="absolute top-0 left-4 w-2 h-2 bg-red-800 rounded-full"></div>}
          <img src="/images/icons/bell.svg" className="w-7 ml-4 mr-8 cursor-pointer " alt="notification" />
        </div>
      </MenuHandler>

      <MenuList className="w-80 max_h_160 " data-sentry-element="MenuList" data-sentry-source-file="Notifications.tsx">{notifications}</MenuList>
    </Menu>;
};
export default Notifications;