import { useEffect, useState } from 'react'
import useSWR from 'swr';
import { GoogleAnalytics } from '@next/third-parties/google'
import { fetchGaId } from '../../pages/api/fetchGaId';

export default function GoogleAnalyticsTags() {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  const urlParts = hostname.split('.');
  const subdomain = urlParts.length > 2 ? urlParts[0] : null;
  
  const { data: customGaId } = useSWR(
    subdomain ? `ga-id-${subdomain}` : null,
    () => fetchGaId(subdomain)
  );

  useEffect(() => {
    if (customGaId && window.gtag) {
      window.gtag('config', customGaId);
    }
  }, [customGaId]);

  return (
    <>
      {customGaId && <GoogleAnalytics gaId={customGaId} />}
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA4_TRACKING_ID} />
    </>
  );
}